import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ItemView from "./ItemView";
import ItemList from "./ItemList";
import Login from "./Login";
import axios from "axios";
import Register from "./Register";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [isLoading, setIsloading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    axios
      .get("/api/checkAuthentication")
      .then((res) => {
        setLoggedIn(res.data.authenticated);
        setUser(res.data.user);
        setIsloading(false);
      })
      .catch((error) => {
        setLoggedIn(false);
        setIsloading(false);
        setUser(null);
      });
  }, []);

  if (isLoading) {
    return <span>Loading...</span>;
  }

  return (
    <Switch>
      <PrivateRoute loggedIn={!loggedIn} path="/login">
        <Login />
      </PrivateRoute>
      <PrivateRoute loggedIn={!loggedIn} path="/register">
        <Register />
      </PrivateRoute>
      <Route path="/item/:itemId">
        <ItemView user={user} />
      </Route>
      <Route path="/">
        <ItemList />
      </Route>
    </Switch>
  );
}

function PrivateRoute({ children, loggedIn, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        loggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default App;
