import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { DateTime } from "luxon";
import io from "socket.io-client";
import Chrono from "./Chrono";
import LoginBtn from "./LoginBtn";

const socket = io.connect("/");

const getMinBid = (currentPrice) => {
  if (currentPrice <= 500) {
    return 10;
  }
  if (currentPrice <= 1000) {
    return 25;
  }
  if (currentPrice <= 2000) {
    return 50;
  }
  return 100;
};

function ItemView({ user }) {
  let { itemId } = useParams();

  const [isLoading, setIsLoading] = useState(true);

  const [socketId] = useState("");

  const [staticInfo, setStaticInfo] = useState(null);
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [message, setMessage] = useState("");
  const [currentPrice, setCurrentPrice] = useState(0);
  const [startPrice, setStartPrice] = useState(0);
  const [bidHistory, setBidHistory] = useState([]);
  const [bidValue, setBidValue] = useState(0);
  const [isFinish, setIsFinish] = useState(false);

  const [isButtonDisabled, setButtonDisabled] = useState(true);


  const [loggedIn, setLoggedIn] = useState(false);

  const onEnd = () => {
    setIsFinish(true);
  };

  const onSubmit = () => {
    socket.emit("bid", { bid: bidValue, itemId, message });
    console.log("emitted");
    setButtonDisabled(true);
    setMessage("");
    let bid = bidValue;
    setBidValue(bid + getMinBid(bid));
  };

  useEffect(async () => {
    await axios
      .get("/api/checkAuthentication")
      .then((res) => {
        setLoggedIn(res.data.authenticated);
      })
      .catch((error) => {
        setLoggedIn(false);
      });

    axios
      .get(`/api/items/${itemId}`)
      .then((res) => {
        setEndDate(DateTime.fromISO(res.data.item.end_date));
        setStartDate(DateTime.fromISO(res.data.item.start_date));
        setCurrentPrice(res.data.item.current_price);
        setStartPrice(res.data.item.start_price);
        setBidHistory(res.data.bids);
        setBidValue(() => {
          const item = res.data.item;
          return (item.start_price > item.current_price ? item.start_price : item.current_price) + getMinBid(item.current_price);
        });
        setButtonDisabled(false);
        setStaticInfo({
          description: res.data.item.description,
          img_url: res.data.item.img_url,
          name: res.data.item.name,
          start_price: res.data.item.start_price,
          start_date: res.data.item.start_date,
          sponsor: res.data.item.sponsor,
          worth: res.data.item.worth
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        //window.location.href = "/"
      });
  }, []);

  const [usersWatching, setUsersWatching] = useState(0);

  useEffect(() => {
    socket.emit("joinRoom", { user, itemId });
    socket.on("connect", () => {
      socket.emit("joinRoom", { user, itemId });
    });
  }, [socket]);

  useEffect(() => {
    socket.on("watchersUpdate", (data) => {
      setUsersWatching(data.nbrWatching - 1);
    });
    socket.on("bidError", (data) => {
      console.log(data);
      setButtonDisabled(false);
    });
    socket.on("newBid", (bid) => {
      setCurrentPrice(bid.bids.amount);
      setButtonDisabled(false);

      console.log(bid);
      console.log(bidHistory);

      setBidHistory([bid, ...bidHistory]);
    });

    socket.on("newEndDate", (newEndDate) => {
      setEndDate(DateTime.fromISO(newEndDate));
    });
  }, [socket, bidHistory]);

  if (isLoading || !staticInfo || !socket) {
    return <>Loading...</>;
  }

  return (
    <div id="page" className="hfeed site">
      {socketId}
      <div className="ibid-single-product-v2">
        <div className="single-product-header">
          <div className="article-details relative text-center">
            <img
              width="200"
              height="200"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/A_black_image.jpg/800px-A_black_image.jpg"
              className="attachment-ibid_single_prod_2 size-ibid_single_prod_2 wp-post-image"
              alt=""
              loading="lazy"
              sizes="(max-width: 200px) 100vw, 200px"
            />
            <div className="header-title-blog text-center">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                    <div className="header-title-blog-box">
                      <h1>{staticInfo.name}</h1>
                      <p>
                        Commandité par{" "}
                        <span style={{ color: "#009ef7", fontWeight: "bold" }}
                        >
                          {staticInfo.sponsor}
                        </span>
                      </p>
                      <p>
                        Valeur de{" "}
                        <span style={{ color: "#d4a619", fontWeight: "bold" }}
                        >
                          {staticInfo.worth}$
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="primary" className="content-area">
          <main id="main" className="site-main" role="main">
            <div className="high-padding">
              <div className="container blog-posts">
                <div className="row">
                  <div className="col-md-12  main-content">
                    <div className="woocommerce-notices-wrapper"></div>
                    <div
                      id="product-6173"
                      className="row post-6173 product type-product status-publish has-post-thumbnail product_cat-anthology product_tag-languages first instock sold-individually shipping-taxable purchasable product-type-auction"
                    >
                      <div className="col-md-12 thumbnails-summary">
                        <div className="row">
                          <div className="col-md-5 product-thumbnails mb-8 ms-3"
                               style={{ backgroundColor: "white" }}>
                            <div
                              className="woocommerce-product-gallery woocommerce-product-gallery--with-images woocommerce-product-gallery--columns-4 images"
                              data-columns="4"
                              style={{
                                opacity: 1,
                                transition: "opacity 0.25s ease-in-out 0s"
                              }}
                            >
                              <figure className="woocommerce-product-gallery__wrapper">
                                <img
                                  src={`../../prix/${staticInfo.img_url}`}
                                  className="wp-post-image"
                                  alt={staticInfo.name}
                                  loading="lazy"
                                  title={staticInfo.name}
                                  data-caption={staticInfo.name}
                                />
                              </figure>
                            </div>
                          </div>

                          <div className="summary entry-summary col-md-7">
                            <h1
                              itemProp="name"
                              className="product_title entry-title"
                            >
                              {staticInfo.name}
                            </h1>
                            <div
                              className="product-description"
                              itemProp="description"
                            >
                            </div>
                            <p
                              style={{ marginBottom: 10, paddingBottom: 5 }}
                              className="price"
                            >
                              <span
                                className="auction-price current-bid"
                                data-auction-id="6173"
                                data-status="running"
                              >
                                {" "}
                                <span
                                  className="auction-price current-bid"
                                  data-auction-id="6173"
                                  data-status="running"
                                >
                                  <span className="current auction">
                                    Mise actuelle:
                                  </span>{" "}
                                  {currentPrice ? (
                                    <span
                                      style={{ float: "right" }}
                                      className="woocommerce-Price-amount amount"
                                    >
                                    <bdi>
                                      <span className="woocommerce-Price-currencySymbol">
                                        $
                                      </span>
                                      {currentPrice.toFixed(0)}
                                    </bdi>
                                  </span>
                                  ) : (
                                    <span
                                      style={{ float: "right" }}
                                      className="woocommerce-Price-amount amount"
                                    >
                                      Aucune mise
                                    </span>
                                  )}
                                </span>
                              </span>
                            </p>

                            <p
                              style={{ marginBottom: 10, paddingBottom: 5 }}
                              className="price"
                            >
                              <span
                                className="auction-price current-bid"
                                data-auction-id="6173"
                                data-bid="120"
                                data-status="running"
                              >
                                {" "}
                                <span
                                  className="auction-price current-bid"
                                  data-auction-id="6173"
                                  data-bid="120"
                                  data-status="running"
                                >
                                  <span className="current auction">
                                    Mise précédente:
                                  </span>{" "}
                                  <span
                                    style={{ float: "right" }}
                                    className="woocommerce-Price-amount amount"
                                  >
                                      <bdi>
                                        {bidHistory.length > 1 ? (
                                          bidHistory[1].bids.amount.toFixed(0)
                                        ) : (
                                          staticInfo.start_price.toFixed(0)
                                        )}
                                        <span className="woocommerce-Price-currencySymbol">
                                          $
                                        </span>
                                      </bdi>
                                  </span>
                                </span>
                              </span>
                            </p>
                            <p
                              style={{ marginBottom: 10, paddingBottom: 5 }}
                              className="price"
                            >
                              <span
                                className="auction-price current-bid"
                                data-auction-id="6173"
                                data-bid="120"
                                data-status="running"
                              >
                                {" "}
                                <span
                                  className="auction-price current-bid"
                                  data-auction-id="6173"
                                  data-bid="120"
                                  data-status="running"
                                >
                                  <span className="current auction">
                                    Meneur:
                                  </span>{" "}
                                  <span
                                    style={{ float: "right" }}
                                    className="woocommerce-Price-amount amount"
                                  >
                                    {bidHistory.length <= 0 && <bdi> N/A</bdi>}
                                    {bidHistory.length > 0 && (
                                      <bdi> {bidHistory[0].users.name}</bdi>
                                    )}
                                  </span>
                                </span>
                              </span>
                            </p>
                            <p
                              style={{ marginBottom: 20, paddingBottom: 5 }}
                              className="price"
                            >
                              <span
                                className="auction-price current-bid"
                                data-auction-id="6173"
                                data-bid="120"
                                data-status="running"
                              >
                                {" "}
                                <span
                                  className="auction-price current-bid"
                                  data-auction-id="6173"
                                  data-bid="120"
                                  data-status="running"
                                >
                                  <span className="current auction">
                                    Spectateurs:
                                  </span>{" "}
                                  <span
                                    style={{ float: "right" }}
                                    className="woocommerce-Price-amount amount"
                                  >
                                    <bdi>{usersWatching}</bdi>
                                  </span>
                                </span>
                              </span>
                            </p>

                            <div
                              className="auction-ajax-change"
                              style={{ marginTop: 30 }}
                            >
                              {!loggedIn ? (
                                <>
                                  <p style={{ marginTop: 10, fontSize: 14 }}>
                                    Vous devez être connecté pour miser.
                                  </p>
                                  <LoginBtn />
                                </>
                              ) : (
                                startDate.diff(DateTime.local(), "seconds").seconds > 0 ? (
                                  <div className="alert alert-info fs-3 text-center" role="alert">
                                    L'enchère n'a pas encore commencé.
                                  </div>
                                ) : (
                                  <>
                                    <Chrono onEnd={onEnd} endDate={endDate} />
                                    <form
                                      style={{ width: "100%" }}
                                      className="auction_form cart"
                                    >
                                      <div
                                        style={{ float: "left" }}
                                        className="quantity buttons_added"
                                      >
                                        <input
                                          type="button"
                                          value="-"
                                          disabled={bidValue <= currentPrice}
                                          onClick={() =>
                                            setBidValue(
                                              Math.max(
                                                bidValue - getMinBid(currentPrice),
                                                currentPrice +
                                                getMinBid(currentPrice)
                                              )
                                            )
                                          }
                                          className="minus"
                                        />
                                        <input
                                          type="number"
                                          name="bid_value"
                                          value={bidValue}
                                          min={
                                            currentPrice + getMinBid(currentPrice)
                                          }
                                          onChange={(e) =>
                                            setBidValue(parseInt(e.target.value))
                                          }
                                          size="5"
                                          title="Miser"
                                          className="input-text qty  bid text left"
                                        />
                                        <input
                                          type="button"
                                          value="+"
                                          onClick={() =>
                                            setBidValue(
                                              bidValue + getMinBid(currentPrice)
                                            )
                                          }
                                          className="plus"
                                        />
                                      </div>
                                      <button
                                        type={"button"}
                                        style={{
                                          float: "right",
                                          textAlignt: "right",
                                          width: 100
                                        }}
                                        className="bid_button button alt"
                                        disabled={
                                          currentPrice + getMinBid(currentPrice) >
                                          bidValue ||
                                          isButtonDisabled ||
                                          isFinish || bidValue <= startPrice
                                        }
                                        onClick={onSubmit}
                                      >
                                        Miser
                                      </button>

                                      <div
                                        className="quantity buttons_added"
                                        style={{ clear: "both", width: 200, marginTop: 5 }}
                                      >
                                        <input
                                          type="text"
                                          name="message"
                                          value={message}
                                          size={2}

                                          onChange={(e) =>
                                            setMessage(e.target.value)
                                          }
                                          title="Message (optionnel)"
                                          placeholder="Message (optionnel)"
                                          className="input-text qty text"
                                          style={{ width: 190, fontSize: 14, paddingLeft: 10 }}
                                        />
                                      </div>
                                    </form>
                                  </>
                                ))}
                            </div>

                            <div className="yith-wcwl-add-to-wishlist add-to-wishlist-6173">
                              <div
                                className="yith-wcwl-add-button show"
                                style={{ display: "block" }}
                              >
                                <a
                                  href="/fundraising-auctions/product/pink-floyd-the-dark-side-of-the-moon/?add_to_wishlist=6173"
                                  data-product-id="6173"
                                  data-product-type="auction"
                                  className="add_to_wishlist single_add_to_wishlist button "
                                  data-tooltip="Add to Wishlist"
                                >
                                  <i className="fa fa-heart-o"></i>
                                </a>
                                <img
                                  src="https://ibid.modeltheme.com/fundraising-auctions/wp-admin/images/wpspin_light.gif"
                                  className="ajax-loading"
                                  alt="loading"
                                  width="16"
                                  height="16"
                                  style={{ visibility: "hidden" }}
                                />
                              </div>

                              <div style={{ clear: "both" }}></div>
                              <div className="yith-wcwl-wishlistaddresponse"></div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 tabs-related">
                        <div className="woocommerce-tabs wc-tabs-wrapper">
                          <ul className="tabs wc-tabs wc-tabs-5" role="tablist">
                            <li
                              className="description_tab active"
                              id="tab-title-description"
                              role="tab"
                              aria-controls="tab-description"
                            >
                              <a href="#tab-description">Description</a>
                            </li>
                            <li
                              className="additional_information_tab"
                              id="tab-title-additional_information"
                              role="tab"
                              aria-controls="tab-additional_information"
                            >
                              <a href="#tab-additional_information">
                                Additional information
                              </a>
                            </li>
                            <li
                              className="simle_auction_history_tab"
                              id="tab-title-simle_auction_history"
                              role="tab"
                              aria-controls="tab-simle_auction_history"
                            >
                              <a href="#tab-simle_auction_history">
                                Historique de mises
                              </a>
                            </li>
                            <li
                              className="seller_tab"
                              id="tab-title-seller"
                              role="tab"
                              aria-controls="tab-seller"
                            >
                              <a href="#tab-seller">Vendor Info</a>
                            </li>
                            <li
                              className="more_seller_product_tab"
                              id="tab-title-more_seller_product"
                              role="tab"
                              aria-controls="tab-more_seller_product"
                            >
                              <a href="#tab-more_seller_product">
                                More from Vendor
                              </a>
                            </li>
                          </ul>
                          <div
                            className="woocommerce-Tabs-panel woocommerce-Tabs-panel--description panel entry-content wc-tab"
                            id="tab-description"
                            role="tabpanel"
                            aria-labelledby="tab-title-description"
                          >
                            <h2>Description</h2>

                            <div dangerouslySetInnerHTML={{ __html: staticInfo.description }}></div>
                          </div>

                          <div
                            className="woocommerce-Tabs-panel woocommerce-Tabs-panel--description panel entry-content wc-tab"
                            id="tab-simle_auction_history"
                            role="tabpanel"
                            aria-labelledby="tab-title-simle_auction_history"
                            style={{ display: "none", width: "100%" }}
                          >
                            <h2>Historique de mises</h2>

                            <table
                              id="auction-history-table-6173"
                              className="auction-history-table"
                            >
                              <thead>
                              <tr>
                                <th>Date</th>
                                <th>Mise</th>
                                <th>Msg</th>
                                <th>Joueur</th>
                              </tr>
                              </thead>
                              <tbody>
                              {bidHistory.map(({ bids, users }) => {
                                return (
                                  <tr key={bids.id}>
                                    <td className="date">
                                      {DateTime.fromISO(
                                        bids.created
                                      ).toFormat("LLL dd T")}
                                    </td>
                                    <td className="bid">
                                        <span className="woocommerce-Price-amount amount">
                                          <bdi>
                                            {bids.amount.toFixed(0)}
                                            <span className="woocommerce-Price-currencySymbol">
                                              $
                                            </span>
                                          </bdi>
                                        </span>
                                    </td>
                                    <td className="username">{bids.message ?? "-"}</td>
                                    <td className="username">{users.name}</td>
                                  </tr>
                                );
                              })}
                              </tbody>

                              <tbody>
                              <tr className="start">
                                <td className="date">
                                  {staticInfo.start_date ? (
                                    DateTime.fromISO(
                                      staticInfo.start_date
                                    ).toFormat("LLL dd T")
                                  ) : (
                                    <span className="woocommerce-Price-amount amount">
                                        <bdi> N/A</bdi>
                                      </span>
                                  )}
                                </td>

                                <td colSpan="1" className="started">
                                  {staticInfo.start_price.toFixed(0)}$
                                </td>

                                <td colSpan="1" className="started">
                                  Début de l'enchère
                                </td>

                                <td colSpan="1" className="started">
                                  {staticInfo.sponsor}
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default ItemView;
