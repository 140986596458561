import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";

function Chrono({ endDate, onEnd }) {
  const calculateTimeLeft = () => {
    const timeNow = DateTime.local();
    const t = {
      days: Math.floor(endDate.diff(timeNow, "days").days),
      hours: Math.floor(endDate.diff(timeNow, "hours").hours % 24),
      minutes: Math.floor(endDate.diff(timeNow, "minutes").minutes % 60),
      seconds: Math.floor(endDate.diff(timeNow, "seconds").seconds % 60),
      finish: endDate.diff(timeNow, "seconds").seconds < 0
    };

    return t;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      const t = calculateTimeLeft();
      if (t.finish) {
        onEnd();
      }
      setTimeLeft(t);
    }, 1000);
  });

  if (!timeLeft) {
    return <span>Loading...</span>;
  }

  return (
    <div className="auction-time" id="countdown">
      <div
        className="main-auction auction-time-countdown hasCountdown"
        data-time="1641600000"
        data-auctionid="6173"
        data-format="yowdHMS"
        style={{ boxShadow: "none", margin: 0, padding: 0, color: "#000000" }}
      >
        {!timeLeft.finish && <span className="countdown_row countdown_show6"
                                   style={{ display: "flex", justifyContent: "center" }}>
          <span className="countdown_section">
            <span className="countdown_amount">{timeLeft.days}</span>
            <br />
            Jours
          </span>
          <span className="countdown_section">
            <span className="countdown_amount">{timeLeft.hours}</span>
            <br />
            Heures
          </span>
          <span className="countdown_section">
            <span className="countdown_amount">{timeLeft.minutes}</span>
            <br />
            Minutes
          </span>
          <span className="countdown_section">
            <span className="countdown_amount">{timeLeft.seconds}</span>
            <br />
            Secondes
          </span>
        </span>}
        {timeLeft.finish && <div style={{ marginTop: 10 }}>Enchère terminée</div>}
      </div>
    </div>
  );
}

export default Chrono;
