import React, { useEffect, useState } from "react";
import axios from "axios";

function LoginBtn () {
  const redirect = () => {
    window.location.href = "/login";
  }

  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(async () => {
    await axios
      .get("/api/checkAuthentication")
      .then((res) => {
        setLoggedIn(res.data.authenticated);
      })
      .catch((error) => {
        setLoggedIn(false);
      });
  }, []);

  return loggedIn ? null :
      <button onClick={redirect} className="btn btn-primary">
        Connexion
      </button>;
}

export default LoginBtn;
